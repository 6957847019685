// scss-docs-start modal-variables
$modal-inner-padding: $spacer !default;
$modal-inner-padding: 0px 0px 0px 0px;

// $modal-footer-margin-between:       .5rem !default;

// $modal-dialog-margin:               .5rem !default;
// $modal-dialog-margin-y-sm-up:       1.75rem !default;

// $modal-title-line-height:           $line-height-base !default;

// $modal-content-color:               null !default;
// $modal-content-bg:                  $white !default;
$modal-content-border-color: $gray-300;
// $modal-content-border-width:        $border-width !default;
$modal-content-border-radius: "5px";
// $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
// $modal-content-box-shadow-xs:       $box-shadow-sm !default;
// $modal-content-box-shadow-sm-up:    $box-shadow !default;

// $modal-backdrop-bg:                 $black !default;
// $modal-backdrop-opacity:            .5 !default;

// $modal-header-border-color:         var(--#{$prefix}border-color) !default;
// $modal-header-border-width:         $modal-content-border-width !default;
// $modal-header-padding-y:            $modal-inner-padding !default;
// $modal-header-padding-x:            $modal-inner-padding !default;
// $modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility
// $modal-header-padding: 16px 16px 2px 16px;modal-body

// $modal-footer-bg:                   null !default;
// $modal-footer-border-color:         $modal-header-border-color !default;
// $modal-footer-border-width:         $modal-header-border-width !default;

// $modal-sm:                          300px !default;
$modal-md: 508px !default;
// $modal-lg:                          800px !default;
// $modal-xl:                          1140px !default;

// $modal-fade-transform:              translate(0, -50px) !default;
// $modal-show-transform:              none !default;
// $modal-transition:                  transform .3s ease-out !default;
// $modal-scale-transform:             scale(1.02) !default;
// scss-docs-end modal-variables

@import "../../../node_modules/bootstrap/scss/variables";

.modal {
  z-index: 10000 !important;
}
.modal-header {
  align-items: flex-start;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-header .btn-close {
  margin-top: -1px;
  margin-right: 0;
}

.modal-backdrop {
  z-index: 9000 !important;
}

.modal-title {
  font-size: 20px;
}

//body > div.fade.modal.show > div > div > div.border-0.modal-header > button
//body > div.fade.modal.show > div > div > div.border-0.modal-header > button
