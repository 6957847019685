.comp-zone-at-a-glance {
  margin: -24px;
}

.comp-zag-container {
  display: flex;

  background-color: $gray-110;

  .comp-zag-banner {
    width: 355px;
    height: 176px;

    display: block;
    flex-grow: 0;
  }

  .comp-zag-charts {
    display: block;
    flex-grow: 1;

    h6 {
      font-weight: 700;
    }

    .comp-zag-chart-container {
      display: flex;

      .comp-zag-open-complaint-chart {
        flex-grow: 0;

        height: 125px;
        width: 163px;
      }

      .comp-zag-legend {
        flex-grow: 1;

        h6 {
          margin-top: 10px;
        }

        & > .row {
          padding-bottom: 4px;
        }

        .comp-zag-legend-row {
          max-width: 190px;
        }

        .comp-zag-legend-bar-col {
          max-width: 55px !important;
          // padding: 0px;
        }

        .comp-zag-legend-bar {
          width: 36px;
          height: 13px;
          display: inline-block;
        }

        .comp-zag-legend-label {
          margin-left: -12px;
          margin-right: 12px;
          width: 110px;
          max-width: 110px;
        }

        .comp-zag-legend-value {
          font-weight: 900;
          width: 40px;
          max-width: 40px;
          padding: 0px;
        }
      }
    }

    .comp-zag-open-compalaints {
      height: 160px;
      width: 160px;
    }
  }
}

.comp-zag-office-container {
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid $gray-200;
  border-radius: 8px;
  padding: 24px;
  font-size: 24px;
  line-height: 30px;

  .comp-zag-flex-container {
    display: flex;
    flex: 1;
    align-items: center;

    .comp-zag-office {
      width: 355px;
      flex: 0 0 355px;
    }
    .comp-zag-office-stats {
      flex: 1;
    }

    .comp-zag-stats-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    .comp-zag-stats-view {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $gray-60;
    }
    .hwcr-unassigned {
      background-color: $bc-standard-04-300;
    }
    .hwcr-assigned {
      background-color: $bc-standard-04-500;
    }
    .allegation-unassigned {
      background-color: $bc-standard-13-300;
    }
    .allegation-assigned {
      background-color: $bc-standard-13-500;
    }

    .comp-zag-bar-text {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      padding-left: 10px;
      padding-top: 5px;
    }

    .negative-office-margin {
      margin-left: -35px;
    }

    .comp-zag-bar-spacing {
      padding-top: 5px;
      padding-bottom: 5px;
      width: 95%;

      .comp-zag-bar-spacer {
        width: 2px;
      }

      .comp-zag-float-bar {
        height: 30px;
        float: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .comp-zag-officer-container {
    margin-top: 15px;
    margin-bottom: 4px;
    margin-right: 12px;
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 24px;
    font-size: 24px;
    line-height: 30px;
    .comp-zag-float-left {
      float: left;
      margin-left: 8px;
      display: grid;
      align-items: center;
      height: 40px;
    }
    .comp-zag-officer {
      width: 330px;
      flex: 0 0 330px;
    }
    .comp-zag-officer-stats {
      flex: 1;
    }
  }

  .comp-zag-data-initials-zagview {
    display: inline;
  }

  .data-initials-zagview[data-initials-listview=""] {
    display: none;
  }

  .comp-zag-data-initials-zagview-hwcr {
    display: inline;
  }

  .data-initials-zagview-hwcr[data-initials-listview=""] {
    display: none;
  }

  .comp-zag-data-initials-zagview-allegation {
    display: inline;
  }

  .data-initials-zagview-allegation[data-initials-listview=""] {
    display: none;
  }
}
