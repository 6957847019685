.comp-icon-button-container {
  width: 24px;
  height: 28px;
  padding: 0px;
  border: 0px;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
}

.comp-icon-button-container:hover > *:first-child,
.comp-icon-button-container:hover > *:last-child {
  transform: translateX(-100%);
}

.comp-fake-label {
  display: inline-block;
  margin-right: 10px;
  min-width: 10px !important;
  max-width: inherit;
  color: $gray-500;
}

//CHECKBOX
.comp-checkbox {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  min-height: 34px;
  padding: 6px 0;

  .form-check-input {
    flex: 0 0 auto;
  }
}

.form-check-input {
  cursor: pointer;
}
.form-check-input:disabled {
  cursor: not-allowed;
  pointer-events: all;
}

// DATE PICKER - FIXES
// Fixes issue where the prop to enable clickable icon doesn't appear to work.
.react-datepicker__input-container svg {
  pointer-events: none;
}

// RADIO GROUPS
.comp-radio-btn {
  display: flex;
  align-items: center;
  min-height: 34px;
  input {
    margin-top: 0;
    margin-right: 0.5rem;
  }
}

// VALIDATION
.error-message {
  margin-top: 4px;
  font-size: 0.875rem;
  color: $bc-gov-danger;
}

.error-message:empty {
  display: none;
}

.validation-group-label {
  padding-left: 25px;
}

@media screen and (max-width: 1024px) {
  .validation-group-sub-label {
    padding-left: 25px;
  }
}

@media screen and (max-width: 1024px) {
  .validation-group-input {
    margin-left: 25px;
    max-width: calc(100% - 25px); /* Prevent overflow by reducing the width */
  }
}

// LAT/LONG INPUTS
.comp-lat-long-input {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  max-width: 500px;
  margin-top: 0.8rem;
}

// LAT/LONG UTM INPUTS
.comp-lat-long-utm-input {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 8px;
  max-width: 500px;
  margin-top: 0.8rem;
}

@media screen and (max-width: 768px) {
  .comp-lat-long-utm-input {
    grid-template-columns: auto;
  }
}

.comp-coordinate-type-radio-group {
  display: flex;
  gap: 8px;
  align-items: center;
}
