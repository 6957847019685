@import "../../../node_modules/bootstrap/scss/functions";

//-- ---
//-- COLORS
//-- ---
$white: #fff !default;
$black: #000 !default;

/* Bootstrap Primary theme override colors */
$bc-gov-primary: #003366;
$bc-gov-secondary: #fcba19;
$bc-gov-success: #2e8540;
$bc-gov-warning: #f1c21b;
$bc-gov-danger: #d8292f;
$bc-gov-info: #d9eaf7;
$bc-gov-light: $white;
$bc-gov-dark: $black;

/* BC Primary */
$bc-brand-blue: #003366;
$bc-brand-gold: #fcba19;
$bc-brand-text: #313132;
$bc-brand-links: #1a5a96;
$bc-brand-background-blue: #38598a;
$bc-brand-background-light-gray: #f2f2f2;

/* BC Standard */
$bc-standard-01: #004f4b !default;
$bc-standard-02: #20754e !default;
$bc-standard-03: #61a744 !default;
$bc-standard-04: #31ba9a !default;
$bc-standard-05: #05a6d1 !default;
$bc-standard-06: #005c98 !default;
$bc-standard-07: #243c7c !default;
$bc-standard-08: #5f3795 !default;
$bc-standard-09: #883895 !default;
$bc-standard-10: #dd5857 !default;
$bc-standard-11: #f38489 !default;
$bc-standard-12: #f2a488 !default;
$bc-standard-13: #ecc51d !default;

/* grayscale */
$gray-60: #656565;
$gray-80: #606060;
$gray-100: #f1f1f1 !default;
$gray-110: #f8f9fa !default;
$gray-200: #e4e4e4 !default;
$gray-300: #c7c7c7 !default;
$gray-310: #b9b9b9 !default;
$gray-400: #a1a1a1 !default;
$gray-500: #8c8c8c !default;
$gray-600: #7a7a7a !default;
$gray-700: #595959 !default;
$gray-800: #313132 !default;
$gray-900: #1a1a1a !default;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;

$theme-colors: (
  "primary": $bc-gov-primary,
  "secondary": $bc-gov-secondary,
  "success": $bc-gov-success,
  "warning": $bc-gov-warning,
  "info": $bc-gov-info,
  "danger": $bc-gov-danger,
  "light": $bc-gov-light,
  "dark": $bc-gov-dark,
) !default;

$custom-theme-colors: (
  "bc-brand-blue": $bc-brand-blue,
  "bc-brand-gold": $bc-brand-gold,
  "bc-brand-text": $bc-brand-text,
  "bc-brand-links": $bc-brand-links,
  "bc-brand-background-blue": $bc-brand-background-blue,
  "bc-brand-background-light-gray": $bc-brand-background-light-gray,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

$orientation: (top, bottom, left, right);

/* spacing */
$space-units: (
  "xxs": 4,
  "xs": 8,
  "sm": 16,
  "md": 24,
  "lg": 32,
  "xl": 40,
  "xxl": 48,
);

$suplimental-space-units: (
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  20,
  25,
  28,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100
);

/* shadows */
$box-shadow: 0px 4px 4px rgba($black, 0.15);
$box-shadow-sm: 0px 8px 16px rgba($black, 0.075);
$box-shadow-lg: 0px 16px 48px rgba($black, 0.175);

/* borders */
$border-units: (
  "xs": 1,
  "sm": 2,
  "md": 4,
  "lg": 8,
  "xl": 16,
  "xxl": 32,
);

/* typography */
$font-family-sans-serif: "BC Sans";

$h1-font-size: 40px;
$h2-font-size: 32px;
$h3-font-size: 28px;
$h4-font-size: 24px;
$h5-font-size: 20px;
$h6-font-size: 16px;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
);

//-- tables
$cell-widths: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  105,
  110,
  115,
  120,
  125,
  130,
  135,
  140,
  145,
  150,
  155,
  160,
  165,
  170,
  175,
  180,
  185,
  190,
  195,
  200,
  225,
  250,
  275,
  300,
  305,
  310,
  330
);

@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/root";
