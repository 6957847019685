// OVERRIDES
.carousel__slider-tray {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  width: 100% !important;
  transform: none !important;
}

@include media-breakpoint-up(md) {
  .carousel__slider-tray {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@include media-breakpoint-up(xl) {
  .carousel__slider-tray {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.carousel__slide {
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 0 !important;
}

div[class^="slideInner"] {
  position: relative;
}

.comp-attachment-upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px dotted $gray-400;
  border-radius: 6px;
  background-color: $gray-110;
  color: $gray-400;
  cursor: pointer;
}

.comp-attachment-upload-btn,
.comp-attachment-slide {
  height: 100%;
  min-height: 150px;
}

.comp-attachment-slide {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border: 1px solid $gray-300;
  border-radius: 6px;
  height: 100%;

  &-pending {
    .comp-attachment-slide-top {
      img,
      svg {
        opacity: 0.25;
      }
    }
  }

  &-error {
    &::before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      outline: 3px solid red;
      outline-offset: -2px;
      border-radius: 6px;
      pointer-events: none;
    }
  }
}

@media screen and (max-device-width: 768px) and (orientation: portrait) {
  .carousel__slider-tray {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .comp-attachment-upload-btn,
  .comp-attachment-slide {
    min-height: 150px;
  }
}

@media screen and (max-device-width: 1024px) and (orientation: landscape) {
  .carousel__slider-tray {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .comp-attachment-upload-btn,
  .comp-attachment-slide {
    min-height: 180px;
  }
}

@media screen and (min-width: 1024px) {
  .carousel__slider-tray {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media screen and (min-width: 1200px) {
  .comp-attachment-upload-btn,
  .comp-attachment-slide {
    min-height: 200px;
  }
}

.comp-attachment-slide-top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  position: relative;
  background: $gray-200;
  overflow: hidden;

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    min-height: 100%;
  }

  svg {
    font-size: 2rem;
  }
}

.comp-attachment-slide-bottom {
  flex: 0 0 auto;
  padding: 8px;
  padding-top: 6px;
  height: 55px;
  font-size: 0.8375rem;
  background: $white;
}

.comp-attachment-slide-name,
.comp-attachment-slide-meta {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.comp-attachment-slide-name {
  font-weight: 700;
}

.comp-attachment-slide-actions {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  display: flex;
  gap: 4px;
}

// LEGACY
.coms-carousel-slide {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-400;
  border-radius: 3px;
  text-align: center;
  overflow: hidden;
}

.coms-carousel:hover .back-icon,
.coms-carousel:hover .next-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-icon,
.next-icon {
  display: none;
  z-index: 1000;
  position: absolute;
  font-size: 48px;
  background-color: $gray-200;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  top: 70px;
  border: none;
  margin-top: 18px;
}

.back-icon {
  left: 20px;
}

.next-icon {
  right: 50px;
}

.back-icon:disabled {
  visibility: hidden;
}

.next-icon:disabled {
  visibility: hidden;
}

.coms-carousel-slide:hover .coms-carousel-actions {
  display: flex;
  gap: 10px;
  z-index: 1; //sit above the preview
}

.coms-carousel-slide:hover .top-section {
  background-color: $gray-400;
}

.coms-carousel-slide .icon {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
}

.coms-carousel-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px dotted $gray-400;
  border-radius: 3px;
  background-color: $gray-110;
  color: $gray-400;
  cursor: pointer;
}

.coms-carousel-upload-container:hover {
  color: black;
}

.upload-icon {
  font-size: 32px;
}

.upload-text {
  font-size: 16px;
}

.coms-carousel-error {
  border-color: red;
}
