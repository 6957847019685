@import "./hwcr-animal-outcome.scss";
@import "./hwcr-equipment.scss";

.comp-hwcr-outcome-report {
  .comp-sub-header {
    margin-bottom: 24px;
    margin-left: 24px;
  }
}

.comp-outcome-report-block {
  h6 {
    padding-top: 0px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .comp-outcome-spacing {
    margin-bottom: -22px;
  }

  .comp-outcome-checkbox-spacing {
    padding-bottom: 12px;
  }

  .comp-outcome-report-container {
    display: flex;
  }

  .comp-outcome-report-top-spacing {
    padding: 12px 0px 0px 0px;
  }

  .comp-outcome-report-inner-spacing {
    padding: 18px 0px 18px 0px;
  }

  .comp-outcome-report-bottom-spacing {
    padding: 0px 0px 12px 0px;
  }

  .comp-outcome-report-edit-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 60px;
    .form-check-spacing {
      padding-bottom: 12px;
    }
  }

  .comp-outcome-report-edit-column button svg {
    font-size: 16px;
    vertical-align: text-top;
    margin-top: 1px;
  }

  .comp-outcome-report-right-column {
    margin-right: 0px;
  }

  .comp-outcome-report-label-column {
    flex: 0.1;
    display: flex;
    flex-direction: column;
    margin-right: 60px;
  }

  .comp-outcome-report-label-half-column {
    flex: 0.25;
    display: flex;
    flex-direction: column;
    margin-right: 60px;
  }

  .comp-outcome-hide {
    visibility: hidden;
  }

  .comp-outcome-report-label-half-column-hidden {
    flex: 0.25;
    display: none;
    flex-direction: column;
    margin-right: 60px;
  }

  .comp-outcome-report-button {
    margin-bottom: 24px;
  }

  .comp-outcome-report-button span {
    margin-right: 8px;
  }

  .comp-outcome-report-button button svg {
    font-size: 18px;
    vertical-align: text-top;
    margin-top: 3px;
  }

  .comp-outcome-report-complaint-prev-and-educ,
  .comp-outcome-report-complaint-assessment {
    margin-bottom: 24px;
    border: 1px solid $gray-300;
    border-radius: 3px;
    padding: 24px;
  }

  .comp-outcome-report-complaint-attachments {
    margin-bottom: 24px;
    border: 1px solid $gray-300;
    border-radius: 3px;
    padding-right: 24px;

    .upload-icon svg {
      font-size: 32px;
    }
  }

  .comp-outcome-supporting-notes {
    margin-bottom: 24px;
    border: 1px solid $gray-300;
    border-radius: 3px;
    padding: 24px;
  }

  .comp-outcome-report-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
  }

  .comp-outcome-report-actions button {
    border-radius: 4px;
    font-size: 14px;
  }

  .comp-outcome-cancel {
    color: $bc-gov-primary-400;
    border-color: $bc-gov-primary-400;
    background-color: $white;
  }

  .comp-outcome-cancel:hover {
    background-color: $bc-gov-primary-400;
    color: $white;
  }

  .comp-outcome-save {
    color: $white;
    background-color: $bc-gov-primary-400;
  }

  .comp-outcome-save:hover {
    background-color: $white;
    color: $bc-gov-primary-400;
  }

  .comp-outcome-report-actions button span {
    padding-right: 8px;
  }

  .comp-outcome-report-actions button svg {
    font-size: 16px;
    vertical-align: text-top;
    margin-top: 1px;
  }

  .checkbox-label-padding {
    padding: 10px 0 0 0;
  }

  .checkbox-left-padding {
    padding: 0 0 0 25px;
  }

  .flex-container {
    display: flex;
  }

  .flex-child:first-child {
    margin-right: 20px;
  }

  .comp-animal-outcome-add-button {
    text-decoration: none;
    margin-bottom: 24px;
    padding: 0px;
    border-width: 0px;
    color: $bc-gov-primary;
    display: block;
  }

  .comp-animal-outcome-report-inner-spacing {
    padding: 18px 0px 18px 0px;
    margin-top: -8px;
  }

  .comp-animal-outcome-report-button {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .comp-animal-outcome-report-button span {
    margin-right: 8px;
  }

  .comp-animal-outcome-report-button button svg {
    font-size: 18px;
    vertical-align: text-top;
    margin-top: 3px;
  }

  .comp-animal-outcome-report-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
  }

  .comp-animal-outcome-report-actions button {
    border-radius: 4px;
    font-size: 14px;
  }

  .comp-animal-outcome-report-actions button span {
    padding-right: 8px;
  }

  .comp-animal-outcome-report-actions button svg {
    font-size: 16px;
    vertical-align: text-top;
    margin-top: 1px;
  }

  .comp-animal-outcome-cancel {
    color: $bc-gov-primary-400;
    border-color: $bc-gov-primary-400;
    background-color: $white;
  }

  .comp-animal-outcome-cancel:hover {
    background-color: $bc-gov-primary-400;
    color: $white;
  }

  .comp-animal-outcome-save {
    color: $white;
    background-color: $bc-gov-primary-400;
  }

  .comp-animal-outcome-save:hover {
    background-color: $white;
    color: $bc-gov-primary-400;
  }

  .comp-animal-outcome {
    margin-bottom: 24px;
    border: 1px solid $gray-300;
    border-radius: 3px;
    padding: 24px;

    .animal-item-edit {
      font-size: 14px;

      :hover {
        text-decoration: underline;
      }
      span {
        font-size: inherit;
        padding-right: 0px;
      }

      svg {
        font-size: inherit;
      }
    }

    .animal-item-delete {
      font-size: 14px;
      padding-right: 10px;
      :hover {
        text-decoration: underline;
      }
      span {
        font-size: inherit;
        margin-right: 4px;
        padding-right: 0px;
      }

      svg {
        font-size: 16px;
      }
    }

    .comp-ear-tag-list {
      list-style: none;
      padding-inline-start: 0px;
      padding-left: 0px;
    }

    .comp-animal-outcome-fill-space {
      width: 100%;
    }

    .comp-drug-item {
      margin-bottom: 24px;
      border: 1px solid $gray-300;
      border-radius: 3px;
      padding: 24px;

      .comp-orange-avatar-sm::before {
        margin-left: 25px;
      }

      .row {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .animal-outcome-item-edit {
        font-size: 14px;

        span {
          margin-right: 7px;
        }
      }
    }

    .comp-animal-outcome-pending {
      color: $bc-gov-danger;
    }
  }

  .comp-outcome-report-file-review {
    margin-bottom: 24px;
    border: 1px solid $gray-300;
    border-radius: 3px;
    padding: 24px;
  }

  .comp-outcome-report-file-review > .comp-details-content-label {
    padding-right: 25px;
    margin-bottom: 16px;
    display: inline-block;
  }

  .comp-outcome-report-file-review > .comp-details-content {
    margin-bottom: 16px;
    display: inline-block;

    i:first-child {
      padding-right: 5px;
    }

    i:last-child {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.section-error {
  border: 3px solid $bc-gov-danger !important;
  border-radius: 4px !important;
}

.section-error-message {
  color: $bc-gov-danger;
  svg {
    margin-top: -2px;
  }
  span {
    margin-left: 8px;
  }
}

// PREVENTION AND EDUCATION COMPNENT
.comp-outcome-report-complaint-prev-and-educ {
  margin-bottom: 24px;
}

// EQUIPMENT COMPONENT
.comp-outcome-equipment {
  margin-bottom: 24px;
}

.comp-equipment-items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comp-equipment-form-radio-group {
  display: flex;
  gap: 8px;
  align-items: center;
}

.comp-equipment-card {
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &.active::before {
    background-color: $green-90;
  }

  &.active.border-danger::before {
    background-color: $bc-gov-danger-300;
    width: 4px;
  }

  &.inactive::before {
    background-color: $gray-300;
    opacity: 0.25;
  }
}

.comp-equipment-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;

  h4 {
    margin: 0;
    font-weight: 700;
  }
}

.comp-equipment-item-header-title {
  display: flex;
  align-items: center;
  gap: 8px;

  .badge {
    margin-top: 2px;
  }
}

.comp-equipment-item-header-actions {
  display: flex;
  gap: 8px;
}

.comp-outcome-supporting-notes {
  margin-bottom: 24px;
}

// OUTCOME REPORT - GENERIC
.comp-outcome-report-form {
  fieldset + fieldset {
    margin-top: 32px;
    padding-top: 0;
    border-top: none;
  }

  legend {
    font-size: 1rem;
    font-weight: 700;
  }

  .row + .row {
    margin-top: 16px;
  }
}

.outcome-authroization-unauthroized-site-prefix {
  background-color: transparent;
  border-right: none;
  color: $gray-700;
  border: 1px solid hsl(0, 0%, 80%);
  padding-right: 0.1875rem;
}

.outcome-authroization-unauthroized-site-input {
  border-left: none !important;
  padding-left: 0.1875rem;
}
