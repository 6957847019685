@import "./colours.scss";

.copy-text {
  text-align: left;
  font-size: 14px;
  color: $gray-500;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 191px;
}

.equipment-form-edit-container {
  display: flex;
  margin: 30px 0px;
}

.equipment-form-label-input-pair {
  display: flex;
  align-items: baseline;

  label {
    color: $gray-700;
    min-width: 191px;
    max-width: 191px;
  }

  .comp-details-input {
    flex-basis: 100%;
    color: $gray-900;

    i.bi {
      color: $gray-500;
    }
  }
  .equipment-form-radiobutton {
    margin: auto 0 auto 20px;
    label {
      color: $gray-900;
      min-width: auto;
    }
  }
  .equipment-form-radiogroup {
    padding: 6px 18px 6px 0;
    border-radius: 5px;
  }
  .equipment-form-radiogroup-error {
    border: 1px solid $bc-gov-danger;
  }
}

.edit-input {
  flex: 1;
}

.equipment-form-error-msg {
  color: $bc-gov-danger;
  font-size: 14px;
  margin: 2px 0px 2px 191px;
}

.equipment-item {
  position: relative;

  .status-bar {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: $bc-gov-success;
    width: 12px;
    height: 100%;
  }

  .equipment-item-header {
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;

      h6 {
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        margin-right: 16px;
        margin-bottom: 0px;
      }
      .badge {
        color: $bc-standard-06;
        background-color: $bc-gov-info;
        font-size: 12px;
        font-weight: 700;
        padding: 0px 12px;
        line-height: 24px;
      }
    }
  }

  .equipment-item-content {
    display: flex;
    margin: 16px 0px;

    .label {
      color: $gray-700;
      min-width: 155px;
    }
  }
}
