/* Typography */
@font-face {
  src: url("../fonts/bc-sans-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-family: "BC Sans";
}
@font-face {
  src: url("../fonts/bc-sans-bold-italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-family: "BC Sans";
}
@font-face {
  src: url("../fonts/bc-sans-italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-family: "BC Sans";
}
@font-face {
  src: url("../fonts/bc-sans-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-family: "BC Sans";
}
