@import "../../../node_modules/bootstrap/scss/functions";
@import "./variables.scss";

/* boostrap primary */
$bc-gov-primary-100: #f0f6fd;
$bc-gov-primary-200: #96abc0;
$bc-gov-primary-300: #6b89a6;
$bc-gov-primary-400: #154878;
$bc-gov-primary-500: $bc-gov-primary;
$bc-gov-primary-600: #001f3e;
$bc-gov-primary-700: #002447;

$primaryVariants: (
  "main": $bc-gov-primary,
  "100": $bc-gov-primary-100,
  "200": $bc-gov-primary-200,
  "300": $bc-gov-primary-300,
  "400": $bc-gov-primary-400,
  "500": $bc-gov-primary-500,
  "600": $bc-gov-primary-600,
  "700": $bc-gov-primary-700,
);

$bc-gov-secondary-100: #fff8e8;
$bc-gov-secondary-200: #fee3a1;
$bc-gov-secondary-300: #fdd77a;
$bc-gov-secondary-400: #fdc640;
$bc-gov-secondary-500: $bc-gov-secondary;
$bc-gov-secondary-600: #9a710f;
$bc-gov-secondary-700: #b08212;

$secondaryVariants: (
  "main": $bc-gov-secondary,
  "100": $bc-gov-secondary-100,
  "200": $bc-gov-secondary-200,
  "300": $bc-gov-secondary-300,
  "400": $bc-gov-secondary-400,
  "500": $bc-gov-secondary-500,
  "600": $bc-gov-secondary-600,
  "700": $bc-gov-secondary-700,
);

$bc-gov-success-100: #eaf3ec;
$bc-gov-success-200: #a9cdb1;
$bc-gov-success-300: #45a766;
$bc-gov-success-400: $bc-gov-success;
$bc-gov-success-500: #1c5127;

$successVariants: (
  "main": $bc-gov-success,
  "100": $bc-gov-success-100,
  "200": $bc-gov-success-200,
  "300": $bc-gov-success-300,
  "400": $bc-gov-success-400,
  "500": $bc-gov-success-500,
);

$bc-gov-danger-100: #fbeaea;
$bc-gov-danger-200: #efa7aa;
$bc-gov-danger-300: $bc-gov-danger;
$bc-gov-danger-400: #84191d;

$dangerVariants: (
  "main": $bc-gov-danger,
  "100": $bc-gov-danger-100,
  "200": $bc-gov-danger-200,
  "300": $bc-gov-danger-300,
  "400": $bc-gov-danger-400,
);

$bc-gov-warning-100: #fef9e8;
$bc-gov-warning-200: #f9e6a2;
$bc-gov-warning-300: $bc-gov-warning;
$bc-gov-warning-400: #694e16;

$warningVariants: (
  "main": $bc-gov-warning,
  "100": $bc-gov-warning-100,
  "200": $bc-gov-warning-200,
  "300": $bc-gov-warning-300,
  "400": $bc-gov-warning-400,
);

$bc-gov-neutral-100: #f2f2f2;
$bc-gov-neutral-200: #bbbbbb;
$bc-gov-neutral-300: #ecf6ff;
$bc-gov-neutral-400: $bc-gov-info;
$bc-gov-neutral-500: #38598a;

$neutralVariants: (
  "main": $bc-gov-info,
  "100": $bc-gov-neutral-100,
  "200": $bc-gov-neutral-200,
  "300": $bc-gov-neutral-300,
  "400": $bc-gov-neutral-400,
  "500": $bc-gov-neutral-500,
);

$bc-gov-shade-100: $white;
$bc-gov-shade-200: #313132;
$bc-gov-shade-300: #1a5a96;

$shadeVariants: (
  "main": $bc-gov-shade-300,
  "100": $bc-gov-shade-100,
  "200": $bc-gov-shade-200,
  "300": $bc-gov-shade-300,
);

/* bc standard color shades */
$bc-standard-01-100: tint-color($bc-standard-01, 80%);
$bc-standard-01-200: tint-color($bc-standard-01, 60%);
$bc-standard-01-300: tint-color($bc-standard-01, 40%);
$bc-standard-01-400: tint-color($bc-standard-01, 20%);
$bc-standard-01-500: $bc-standard-01 !default;
$bc-standard-01-600: shade-color($bc-standard-01, 20%);
$bc-standard-01-700: shade-color($bc-standard-01, 40%);
$bc-standard-01-800: shade-color($bc-standard-01, 60%);
$bc-standard-01-900: shade-color($bc-standard-01, 80%);

$bc-standard-01-variants: (
  "100": $bc-standard-01-100,
  "200": $bc-standard-01-200,
  "300": $bc-standard-01-300,
  "400": $bc-standard-01-400,
  "500": $bc-standard-01-500,
  "600": $bc-standard-01-600,
  "700": $bc-standard-01-700,
  "800": $bc-standard-01-800,
  "900": $bc-standard-01-900,
);

$bc-standard-02-100: tint-color($bc-standard-02, 80%);
$bc-standard-02-200: tint-color($bc-standard-02, 60%);
$bc-standard-02-300: tint-color($bc-standard-02, 40%);
$bc-standard-02-400: tint-color($bc-standard-02, 20%);
$bc-standard-02-500: $bc-standard-02 !default;
$bc-standard-02-600: shade-color($bc-standard-02, 20%);
$bc-standard-02-700: shade-color($bc-standard-02, 40%);
$bc-standard-02-800: shade-color($bc-standard-02, 60%);
$bc-standard-02-900: shade-color($bc-standard-02, 80%);

$bc-standard-02-variants: (
  "100": $bc-standard-02-100,
  "200": $bc-standard-02-200,
  "300": $bc-standard-02-300,
  "400": $bc-standard-02-400,
  "500": $bc-standard-02-500,
  "600": $bc-standard-02-600,
  "700": $bc-standard-02-700,
  "800": $bc-standard-02-800,
  "900": $bc-standard-02-900,
);

$bc-standard-03-100: tint-color($bc-standard-03, 80%);
$bc-standard-03-200: tint-color($bc-standard-03, 60%);
$bc-standard-03-300: tint-color($bc-standard-03, 40%);
$bc-standard-03-400: tint-color($bc-standard-03, 20%);
$bc-standard-03-500: $bc-standard-03 !default;
$bc-standard-03-600: shade-color($bc-standard-03, 20%);
$bc-standard-03-700: shade-color($bc-standard-03, 40%);
$bc-standard-03-800: shade-color($bc-standard-03, 60%);
$bc-standard-03-900: shade-color($bc-standard-03, 80%);

$bc-standard-03-variants: (
  "100": $bc-standard-03-100,
  "200": $bc-standard-03-200,
  "300": $bc-standard-03-300,
  "400": $bc-standard-03-400,
  "500": $bc-standard-03-500,
  "600": $bc-standard-03-600,
  "700": $bc-standard-03-700,
  "800": $bc-standard-03-800,
  "900": $bc-standard-03-900,
);

$bc-standard-04-100: tint-color($bc-standard-04, 80%);
$bc-standard-04-200: tint-color($bc-standard-04, 60%);
$bc-standard-04-300: tint-color($bc-standard-04, 40%);
$bc-standard-04-400: tint-color($bc-standard-04, 20%);
$bc-standard-04-500: $bc-standard-04 !default;
$bc-standard-04-600: shade-color($bc-standard-04, 20%);
$bc-standard-04-700: shade-color($bc-standard-04, 40%);
$bc-standard-04-800: shade-color($bc-standard-04, 60%);
$bc-standard-04-900: shade-color($bc-standard-04, 80%);

$bc-standard-04-variants: (
  "100": $bc-standard-04-100,
  "200": $bc-standard-04-200,
  "300": $bc-standard-04-300,
  "400": $bc-standard-04-400,
  "500": $bc-standard-04-500,
  "600": $bc-standard-04-600,
  "700": $bc-standard-04-700,
  "800": $bc-standard-04-800,
  "900": $bc-standard-04-900,
);

$bc-standard-05-100: tint-color($bc-standard-05, 80%);
$bc-standard-05-200: tint-color($bc-standard-05, 60%);
$bc-standard-05-300: tint-color($bc-standard-05, 40%);
$bc-standard-05-400: tint-color($bc-standard-05, 20%);
$bc-standard-05-500: $bc-standard-05 !default;
$bc-standard-05-600: shade-color($bc-standard-05, 20%);
$bc-standard-05-700: shade-color($bc-standard-05, 40%);
$bc-standard-05-800: shade-color($bc-standard-05, 60%);
$bc-standard-05-900: shade-color($bc-standard-05, 80%);

$bc-standard-05-variants: (
  "100": $bc-standard-05-100,
  "200": $bc-standard-05-200,
  "300": $bc-standard-05-300,
  "400": $bc-standard-05-400,
  "500": $bc-standard-05-500,
  "600": $bc-standard-05-600,
  "700": $bc-standard-05-700,
  "800": $bc-standard-05-800,
  "900": $bc-standard-05-900,
);

$bc-standard-06-100: tint-color($bc-standard-06, 80%);
$bc-standard-06-200: tint-color($bc-standard-06, 60%);
$bc-standard-06-300: tint-color($bc-standard-06, 40%);
$bc-standard-06-400: tint-color($bc-standard-06, 20%);
$bc-standard-06-500: $bc-standard-06 !default;
$bc-standard-06-600: shade-color($bc-standard-06, 20%);
$bc-standard-06-700: shade-color($bc-standard-06, 40%);
$bc-standard-06-800: shade-color($bc-standard-06, 60%);
$bc-standard-06-900: shade-color($bc-standard-06, 80%);

$bc-standard-06-variants: (
  "100": $bc-standard-06-100,
  "200": $bc-standard-06-200,
  "300": $bc-standard-06-300,
  "400": $bc-standard-06-400,
  "500": $bc-standard-06-500,
  "600": $bc-standard-06-600,
  "700": $bc-standard-06-700,
  "800": $bc-standard-06-800,
  "900": $bc-standard-06-900,
);

$bc-standard-07-100: tint-color($bc-standard-07, 80%);
$bc-standard-07-200: tint-color($bc-standard-07, 60%);
$bc-standard-07-300: tint-color($bc-standard-07, 40%);
$bc-standard-07-400: tint-color($bc-standard-07, 20%);
$bc-standard-07-500: $bc-standard-07 !default;
$bc-standard-07-600: shade-color($bc-standard-07, 20%);
$bc-standard-07-700: shade-color($bc-standard-07, 40%);
$bc-standard-07-800: shade-color($bc-standard-07, 60%);
$bc-standard-07-900: shade-color($bc-standard-07, 80%);

$bc-standard-07-variants: (
  "100": $bc-standard-07-100,
  "200": $bc-standard-07-200,
  "300": $bc-standard-07-300,
  "400": $bc-standard-07-400,
  "500": $bc-standard-07-500,
  "600": $bc-standard-07-600,
  "700": $bc-standard-07-700,
  "800": $bc-standard-07-800,
  "900": $bc-standard-07-900,
);

$bc-standard-08-100: tint-color($bc-standard-08, 80%);
$bc-standard-08-200: tint-color($bc-standard-08, 60%);
$bc-standard-08-300: tint-color($bc-standard-08, 40%);
$bc-standard-08-400: tint-color($bc-standard-08, 20%);
$bc-standard-08-500: $bc-standard-08 !default;
$bc-standard-08-600: shade-color($bc-standard-08, 20%);
$bc-standard-08-700: shade-color($bc-standard-08, 40%);
$bc-standard-08-800: shade-color($bc-standard-08, 60%);
$bc-standard-08-900: shade-color($bc-standard-08, 80%);

$bc-standard-08-variants: (
  "100": $bc-standard-08-100,
  "200": $bc-standard-08-200,
  "300": $bc-standard-08-300,
  "400": $bc-standard-08-400,
  "500": $bc-standard-08-500,
  "600": $bc-standard-08-600,
  "700": $bc-standard-08-700,
  "800": $bc-standard-08-800,
  "900": $bc-standard-08-900,
);

$bc-standard-09-100: tint-color($bc-standard-09, 80%);
$bc-standard-09-200: tint-color($bc-standard-09, 60%);
$bc-standard-09-300: tint-color($bc-standard-09, 40%);
$bc-standard-09-400: tint-color($bc-standard-09, 20%);
$bc-standard-09-500: $bc-standard-09 !default;
$bc-standard-09-600: shade-color($bc-standard-09, 20%);
$bc-standard-09-700: shade-color($bc-standard-09, 40%);
$bc-standard-09-800: shade-color($bc-standard-09, 60%);
$bc-standard-09-900: shade-color($bc-standard-09, 80%);

$bc-standard-09-variants: (
  "100": $bc-standard-09-100,
  "200": $bc-standard-09-200,
  "300": $bc-standard-09-300,
  "400": $bc-standard-09-400,
  "500": $bc-standard-09-500,
  "600": $bc-standard-09-600,
  "700": $bc-standard-09-700,
  "800": $bc-standard-09-800,
  "900": $bc-standard-09-900,
);

$bc-standard-10-100: tint-color($bc-standard-10, 80%);
$bc-standard-10-200: tint-color($bc-standard-10, 60%);
$bc-standard-10-300: tint-color($bc-standard-10, 40%);
$bc-standard-10-400: tint-color($bc-standard-10, 20%);
$bc-standard-10-500: $bc-standard-10 !default;
$bc-standard-10-600: shade-color($bc-standard-10, 20%);
$bc-standard-10-700: shade-color($bc-standard-10, 40%);
$bc-standard-10-800: shade-color($bc-standard-10, 60%);
$bc-standard-10-900: shade-color($bc-standard-10, 80%);

$bc-standard-10-variants: (
  "100": $bc-standard-10-100,
  "200": $bc-standard-10-200,
  "300": $bc-standard-10-300,
  "400": $bc-standard-10-400,
  "500": $bc-standard-10-500,
  "600": $bc-standard-10-600,
  "700": $bc-standard-10-700,
  "800": $bc-standard-10-800,
  "900": $bc-standard-10-900,
);

$bc-standard-11-100: tint-color($bc-standard-11, 80%);
$bc-standard-11-200: tint-color($bc-standard-11, 60%);
$bc-standard-11-300: tint-color($bc-standard-11, 40%);
$bc-standard-11-400: tint-color($bc-standard-11, 20%);
$bc-standard-11-500: $bc-standard-11 !default;
$bc-standard-11-600: shade-color($bc-standard-11, 20%);
$bc-standard-11-700: shade-color($bc-standard-11, 40%);
$bc-standard-11-800: shade-color($bc-standard-11, 60%);
$bc-standard-11-900: shade-color($bc-standard-11, 80%);

$bc-standard-11-variants: (
  "100": $bc-standard-11-100,
  "200": $bc-standard-11-200,
  "300": $bc-standard-11-300,
  "400": $bc-standard-11-400,
  "500": $bc-standard-11-500,
  "600": $bc-standard-11-600,
  "700": $bc-standard-11-700,
  "800": $bc-standard-11-800,
  "900": $bc-standard-11-900,
);

$bc-standard-12-100: tint-color($bc-standard-12, 80%);
$bc-standard-12-200: tint-color($bc-standard-12, 60%);
$bc-standard-12-300: tint-color($bc-standard-12, 40%);
$bc-standard-12-400: tint-color($bc-standard-12, 20%);
$bc-standard-12-500: $bc-standard-12 !default;
$bc-standard-12-600: shade-color($bc-standard-12, 20%);
$bc-standard-12-700: shade-color($bc-standard-12, 40%);
$bc-standard-12-800: shade-color($bc-standard-12, 60%);
$bc-standard-12-900: shade-color($bc-standard-12, 80%);

$bc-standard-12-variants: (
  "100": $bc-standard-12-100,
  "200": $bc-standard-12-200,
  "300": $bc-standard-12-300,
  "400": $bc-standard-12-400,
  "500": $bc-standard-12-500,
  "600": $bc-standard-12-600,
  "700": $bc-standard-12-700,
  "800": $bc-standard-12-800,
  "900": $bc-standard-12-900,
);

$bc-standard-13-100: tint-color($bc-standard-13, 80%);
$bc-standard-13-200: tint-color($bc-standard-13, 60%);
$bc-standard-13-300: tint-color($bc-standard-13, 40%);
$bc-standard-13-400: tint-color($bc-standard-13, 20%);
$bc-standard-13-500: $bc-standard-13 !default;
$bc-standard-13-600: shade-color($bc-standard-13, 20%);
$bc-standard-13-700: shade-color($bc-standard-13, 40%);
$bc-standard-13-800: shade-color($bc-standard-13, 60%);
$bc-standard-13-900: shade-color($bc-standard-13, 80%);

$bc-standard-13-variants: (
  "100": $bc-standard-13-100,
  "200": $bc-standard-13-200,
  "300": $bc-standard-13-300,
  "400": $bc-standard-13-400,
  "500": $bc-standard-13-500,
  "600": $bc-standard-13-600,
  "700": $bc-standard-13-700,
  "800": $bc-standard-13-800,
  "900": $bc-standard-13-900,
);

//-- colors not specified explicitly
$orange-200: #fecba1;
$orange-600: #ca6510;

$blue-100: #90b6f8;
$blue-200: #9ec5fe;
$blue-300: #ccddfd;
$blue-400: #d9eaf7;
$blue-600: #0a58ca;

$green-100: #2e8540;
$green-90: #107c41;

$green-100-80: tint-color($green-100, 80%);
$green-90-10: tint-color($green-90, 90%);

$red-300: #d8292f;
$red-300-80: tint-color($red-300, 80%);

$gray-700-10: rgba($gray-700, 0.1);

$bc-gov-link-blue: #2464a4;
