.map-marker {
  color: red;
  font-size: 40px;
  text-align: center;
  line-height: 20px;
}

.map-container {
  z-index: 1;
}

.map-comp-summary-popup-container {
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 300px;
}

.map-comp-popup-header-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.map-comp-summary-popup-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.map-comp-summary-popup-header .complaint-identifier {
  font-weight: bold;
  font-size: 24px;
  margin-right: auto;
}

.map-comp-summary-popup-header .complaint-assignee {
  font-size: 16px;
  margin: 5px 0;
}

.map-comp-summary-popup-subheading {
  display: flex;

  gap: 12px;
  color: $white;
  white-space: nowrap;
}

.map-comp-summary-popup-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;

  .comp-details-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px !important;
  }

  .comp-details-content label {
    margin-right: 10px;
    color: $gray-600;
  }

  .comp-complaint-info {
    display: inline !important;
    align-items: center;
  }
}

.map-comp-nature-of-complaint {
  font-weight: bold;
  font-size: 16px;
}

.map-comp-summary-popup-details-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.map-comp-summary-popup-subheading .comp-box-conflict-type {
  margin: 0px 12px;
  padding: 4px 12px;
  border-radius: 60px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  display: flex;
}

.map-comp-summary-popup-subheading .comp-box-conflict-type.hwcr-conflict-type {
  margin: 0px !important;
}

.map-comp-summary-popup-subheading .comp-box-conflict-type.allegation-conflict-type {
  margin: 0px !important;
}

.map-comp-popup-address {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.popup-visible {
  visibility: visible;
}

.popup-invisible {
  visibility: visible;
}

// MAP POPUP
.leaflet-popup-tip {
  display: none; /* Hide the default tip (pointer) */
}

.leaflet-popup-content-wrapper {
  width: 360px;
}

.leaflet-popup-content {
  width: auto !important;
  margin: 12px;
  font-size: 1rem;
}

.comp-map-popup-header {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid $gray-300;
}

.comp-map-popup-header-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 4px;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .badge {
    margin-top: 2px;
    line-height: normal;
  }
}

.comp-map-popup-details {
  font-size: 0.875rem;

  dl div {
    display: flex;
    flex-direction: row;
    gap: 8px;

    + div {
      margin-top: 4px;
    }

    dt {
      flex: 0 0 auto;
      width: 8rem;
      font-weight: 400;
    }

    dd {
      margin-bottom: 0;
    }
  }

  .btn {
    color: #fff;
  }
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font:
    12px "Helvetica Neue",
    Arial,
    Helvetica,
    sans-serif;
}

.marker-cluster span {
  line-height: 30px;
}

.comp-summary-popup-details {
  color: $gray-700;
}