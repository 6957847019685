.comp-animal-card {
  margin-bottom: 16px;

  .comp-card-header {
    padding-top: 16px;
  }

  dl {
    flex-direction: row;
    flex-wrap: wrap;

    > div {
      flex-direction: column;
      gap: 0;
    }
  }

  dl:empty {
    display: none;
  }
}

// EAR TAGS
.comp-ear-tag {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  + .comp-ear-tag {
    margin-top: 16px;
  }

  label {
    color: $gray-700;
  }

  .btn {
    margin-top: 24px;
    min-height: 38px;
  }

  + .btn {
    margin-top: 16px;
  }
}

.comp-ear-tag-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

@include media-breakpoint-up(lg) {
  .comp-ear-tag {
    .btn {
      margin-top: 0;
    }
  }

  .comp-ear-tag-form-item {
    flex-direction: row;
    gap: 8px;

    label {
      margin-top: 6px;
    }
  }
}

.comp-form-control.comp-ear-tag-id-input {
  width: 120px;
}

.comp-ear-tag-side-input {
  min-width: 120px;
}

.comp-ear-tag .comp-ear-tag-actions {
  display: flex;
  align-items: flex-end;

  .btn {
    min-height: 38px;
  }
}

// DRUG LIST AND FORM
.comp-drug-list {
  list-style-type: none;
  padding-left: 0 !important;
}

.comp-drug-item {
  border-radius: 0;
  border-bottom: none;
  border-left: none;
  border-right: none;

  .card-body {
    padding-left: 0;
    padding-right: 0;
  }

  dl {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 8px;

    > div {
      flex-direction: column;
      gap: 0;
      width: 50%;
    }

    dd {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  + .comp-drug-item {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.comp-drug-form {
  display: flex;
  flex-direction: column;
  border-radius: 0;
  border-left: none;
  border-right: none;

  .card-body {
    padding-right: 0;
    padding-left: 0;
  }

  .comp-add-drug-btn {
    order: 2;
    align-self: flex-start;
  }

  + .comp-drug-form {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:last-of-type {
    margin-bottom: 16px;
  }
}

.comp-animal-drugs-authorized-by {
  order: 3;
  margin-top: 32px;
}
