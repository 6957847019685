.comp-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $gray-900;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid hsl(0, 0%, 80%);
  appearance: none;
  border-radius: 0.375rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.comp-form-control:focus-visible {
  border: 1px solid $blue-100 !important;
  outline: none;
  box-shadow: 0px 0px 0px 4px $blue-300 !important;
}

.comp-form-control:disabled {
  background-color: $gray-200;
  opacity: 1;
}

.comp-badge {
  position: relative;
  top: -15px;
  left: -4px;
}

[data-initials]:before {
  background: $bc-gov-info;
  color: $black;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 18px;
  text-align: center;
}

[data-initials-zagview]:before {
  background-color: $bc-standard-12-200;
  color: $bc-standard-12-600;
  opacity: 1;
  content: attr(data-initials-zagview);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  margin-right: 8px;
}

[data-initials-zagview-hwcr]:before {
  background-color: $bc-standard-04-500;
  color: white;
  opacity: 1;
  content: attr(data-initials-zagview-hwcr);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  margin-right: 8px;
}

[data-initials-zagview-allegation]:before {
  background-color: $bc-standard-13-500;
  color: white;
  opacity: 1;
  content: attr(data-initials-zagview-allegation);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  margin-right: 8px;
}

[data-initials-listview]:before {
  background: $bc-gov-info;
  color: $gray-800;
  opacity: 1;
  content: attr(data-initials-listview);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  margin-right: 8px;
  background-color: $gray-200;
}

[data-initials-sm]:before {
  content: attr(data-initials-sm);
}

[data-initials-modal]:before {
  content: attr(data-initials-modal);
}

// Refactor Avatar Styling
.comp-avatar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  overflow: hidden;

  &::before {
    flex: 0 0 auto;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
  }

  // Sizing
  &-sm::before {
    width: 2rem;
    height: 2rem;
    font-size: 0.875rem;
    line-height: 2rem;
  }

  span {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Color Variations
  &-orange::before {
    color: $orange-600;
    background: $orange-200;
  }

  &-blue::before {
    color: $blue-600;
    background: $blue-200;
  }

  &-pink::before {
    color: $bc-standard-12-600;
    background: $bc-standard-12-200;
  }
}

.comp-orange-avatar-sm::before {
  color: $orange-600;
  background: $orange-200;
  opacity: 1;
  content: attr(data-initials-sm);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  text-align: center;
  margin-right: 5px;
}

.comp-blue-avatar-sm::before {
  color: $blue-600;
  background: $blue-200;
  opacity: 1;
  content: attr(data-initials-sm);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  text-align: center;
  margin-right: 5px;
}

.comp-pink-avatar-sm::before {
  color: $bc-standard-12-600;
  background: $bc-standard-12-200;
  opacity: 1;
  content: attr(data-initials-sm);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  text-align: center;
  margin-right: 5px;
}

.comp-blue-avatar-sm {
  span {
    position: relative;
    top: 3px;
  }
}

.comp-orange-avatar-sm {
  span {
    position: relative;
    top: 3px;
  }
}

.comp-pink-avatar-sm {
  margin-top: -5px;
  span {
    position: relative;
    top: 3px;
  }
}

.bs-popover-bottom > .popover-arrow {
  margin-top: 1px;
}

.datepicker-nav-icon {
  top: 5px;
}

.datepicker-nav-icon-hidden {
  visibility: hidden;
}

.datepicker-nav-icon-visible {
  visibility: visible;
}

.react-datepicker__input-container input {
  padding: 6px 10px 5px 10px;
  width: 100%;
  background-color: hsl(0, 0%, 95%);
  border-color: hwb(0 90% 10%);
  color: hsl(0, 0%, 60%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 32px;
  width: 100%;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  color: black;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.react-datepicker__input-container > .react-datepicker__calendar-icon {
  padding: 0px;
  margin: 10px;
  position: absolute;
}

.datepicker-disabled > .react-datepicker__view-calendar-icon input {
  background-color: hsl(0, 0%, 95%);
  border-color: hwb(0 90% 10%);
  color: hsl(0, 0%, 60%);
}

.filter-pill-close {
  pointer-events: auto;
}

.comp-loader-overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba($white, 0.4);

  .comp-overlay-content {
    height: 100%;
  }
}

.width-full {
  width: 100%;
}

.comp-search-input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: $gray-500;

  input {
    padding-left: 40px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    z-index: 2;
  }

  .btn-close {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    margin-top: 0;
    padding: 8px;
    border-radius: 4px;
    outline: none;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.comp-tooltip {
  position: fixed;
  z-index: 9000 !important;
}

.comp-tooltip .tooltip-inner {
  background-color: $bc-gov-primary;
}

.comp-tooltip-right .tooltip-arrow::before {
  border-right-color: $bc-gov-primary !important;
}

.comp-tooltip-left .tooltip-arrow::before {
  border-right-color: $bc-gov-primary !important;
}

.comp-tooltip-top .tooltip-arrow::before {
  border-right-color: $bc-gov-primary !important;
}

.comp-tooltip-bottom .tooltip-arrow::before {
  border-right-color: $bc-gov-primary !important;
}
